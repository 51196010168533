import CenteredColumn from './CenteredColumn';
import Error from './Error';
import Main from './Main';

export { ClientError, ServerError } from './Error';
export const Layouts = {
  Main,
  CenteredColumn,
  Error,
};

export default Layouts;
