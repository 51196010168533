import { twMerge } from 'tailwind-merge';

import type { PropsWithChildren } from 'react';
import type { Theme } from '-/leap-pages';

export interface CenteredColumnProps extends PropsWithChildren {
  className?: string;
  theme?: Theme;
}

export const CenteredColumn = ({
  children,
  className,
  theme,
}: CenteredColumnProps) => {
  return (
    <div
      className={twMerge(
        'mx-auto max-w-2xl flex flex-col items-center min-h-full',
        'text-text-primary font-theme-body',
        'shadow-theme-body',
        `theme-style--${theme?.parameters?.style}`,
        theme?.parameters?.bgStyle === 'solid'
          ? 'bg-background'
          : 'bg-gradient-theme-background',
        className
      )}
    >
      {children}
    </div>
  );
};

export default CenteredColumn;
