import { Heading, Text, LogoExtendedTwirl } from '-/aslan';
import { useT } from '-/i18n';
import { twMerge } from 'tailwind-merge';

import type { PropsWithChildren } from 'react';

export interface ErrorLayoutProps {
  children: PropsWithChildren<React.ReactNode>;
  className?: string;
  logoRoot?: string;
}

export function ClientError({ status }: { status: number }) {
  const customResponses = [400, 401, 403, 404];
  const value = customResponses.includes(status) ? status : 'default';
  const t = useT('translation', `leapPages.error.client.${value}`);
  return (
    <>
      <Heading as="h1" size="3xl" className="font-serif">
        {t('line1')}
      </Heading>
      <Text size="lg">{t('line2')}</Text>
      {value === 404 ? <Text size="lg">{t('line3')}</Text> : null}
    </>
  );
}

export function ServerError() {
  const t = useT('translation', 'leapPages.error.server');
  return (
    <>
      <Heading as="h1" size="3xl" className="font-serif">
        {t('line1')}
      </Heading>
      <Text size="lg">{t('line2')}</Text>
      <Text size="lg">
        {t('line3')}{' '}
        <a className="underline" href="mailto:beta@theleap.co">
          {t('contactUs')}
        </a>
      </Text>
    </>
  );
}

export const Error = ({ children, className, logoRoot }: ErrorLayoutProps) => {
  return (
    <div
      className={twMerge(
        'w-full min-h-full error-page-background-image text-center bg-gray-200 text-brown-900',
        className
      )}
      data-testid="error-layout"
    >
      <main className="flex flex-col space-y-10 items-center justify-center mx-auto w-full max-w-xl p-4">
        {/* Header */}
        <LogoExtendedTwirl wiggle root={logoRoot} />
        {/* Error Response Custom Messaging */}
        {children}
      </main>
    </div>
  );
};

export default Error;
