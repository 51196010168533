import { useParams } from '@remix-run/react';
import { twMerge } from 'tailwind-merge';

import { TheLeapLogo, getInnerButtonParentClasses } from '-/aslan';
import { useT } from '-/i18n';

export type PLG = {
  type: PLGFooterTypes;
  referralCode?: string;
  text?: string;
};

export enum PLGFooterTypes {
  LINK = 'link',
  LOGO = 'logo',
  BUTTON = 'button',
  CLAIM = 'claim',
}

// Subtle non sticky footer with link shown when subscribed to pro on storefront.
// Currently not in use as part of the bets for Q4, 2024. Keeping it around in case we
// want to bring it back with the toggle
export const LinkPLGFooter = ({
  text,
  plgUrl,
}: {
  text?: string;
  plgUrl: string;
}) => {
  const t = useT('translation', 'leapPages.poweredByFooter');
  return (
    <div className="flex gap-1 py-4 mx-auto text-xs">
      {text || t('poweredBy')}
      <a
        href={plgUrl}
        className={'underline hover:no-underline'}
        target="_blank"
        rel="noreferrer noopener"
      >
        {t('theLeap')}
      </a>
    </div>
  );
};

// Subtle non sticky footer with logo shown when subscribed to pro on sales page.
const LogoPLGFooter = ({ text, plgUrl }: { text?: string; plgUrl: string }) => {
  const t = useT('translation', 'leapPages.poweredByFooter');
  return (
    <div className="w-full flex gap-1 justify-center py-4 text-xs bg-footer-background">
      {text || t('poweredBy')}
      <a
        href={plgUrl}
        className={'cursor-pointer'}
        target="_blank"
        rel="noreferrer noopener"
      >
        <TheLeapLogo className="max-w-16" />
      </a>
    </div>
  );
};

// Big sticky buttons shown on the free plan
const ButtonPLGFooter = ({
  text,
  plgUrl,
}: {
  text?: string;
  plgUrl: string;
}) => {
  const t = useT('translation', 'leapPages.poweredByFooter');
  return (
    <div className="mb-20 w-full flex justify-center">
      <div className="fixed flex justify-center bottom-4">
        <a
          href={plgUrl}
          className={getInnerButtonParentClasses(
            'hover:gradient-outline group group-hover:gradient-outline group-hoverhover:after:rounded-full group-hoverhover:after:animate-border group-hover:shadow-none'
          )}
          target="_blank"
          rel="noreferrer noopener"
        >
          <div
            className={twMerge(
              'flex items-center justify-center',
              'mx-auto px-4 md:px-6 py-3 gap-3',
              'rounded-full z-30',
              'powered-by-button'
            )}
          >
            {text || t('plgFooter')}
            <TheLeapLogo className="max-w-24 md:max-w-28" />
          </div>
        </a>
      </div>
    </div>
  );
};

// Big sticky buttons shown when previewing a pre-generated store
const OutreachFooter = () => {
  const t = useT('translation', 'leapPages.poweredByFooter');
  return (
    <ButtonPLGFooter
      text={t('outreachFooter')}
      plgUrl={'https://make.theleap.co/accounts/signup'}
    />
  );
};

const TYPE_TO_FOOTER = {
  [PLGFooterTypes.LINK]: () => <div className="p-4" />, //LinkPLGFooter,
  [PLGFooterTypes.LOGO]: LogoPLGFooter,
  [PLGFooterTypes.BUTTON]: ButtonPLGFooter,
  [PLGFooterTypes.CLAIM]: OutreachFooter,
};

export default function PoweredByFooter({
  type,
  referralCode,
  text,
  className,
}: PLG & {
  className?: string;
}) {
  const Footer = TYPE_TO_FOOTER[type];
  const params = useParams();
  const PLG_URL = `https://www.theleap.co/?utm_campaign=evergreen&utm_medium=plg&utm_source=storefront&utm_content=${
    params.handle
  }${referralCode ? `&ref=${referralCode}` : ''}`;

  return (
    <div
      className={twMerge('w-full flex justify-center items-center', className)}
    >
      <Footer text={text} plgUrl={PLG_URL} />
    </div>
  );
}

export { PoweredByFooter };
